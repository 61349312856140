import { ReactNode } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { DialogTitle, IconButton } from "@mui/material";

export interface BootstrapDialogTitleProps {
  id: string;
  children?: ReactNode;
  onClose: () => void;
}

export const BootstrapDialogTitle = (props: BootstrapDialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ padding: "25px 30px" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: "25px",
            top: "20px",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            color: (theme) => (theme as any).palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
