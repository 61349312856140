import { ReactNode, useEffect, useState } from "react";

import "@fllite/ui/stylesheets/font-face.css";
import "@fllite/ui/stylesheets/react-toggle.css";
import "react-day-picker/src/style.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "@fllite/shared/yupConfig";
import "mapbox-gl/dist/mapbox-gl.css";

import { CssBaseline } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AppProps } from "next/app";
import Head from "next/head";
import Script from "next/script";

import { CookieBar, FlashMessages } from "@fllite/ui/components";
import { GlobalStyles } from "@fllite/ui/components";
import { muiTheme } from "@fllite/ui/themes";
import {
  AccountContextWrapper,
  FronfacingAuthWrapper,
  ProvideAnalytics,
  useSentry,
} from "@fllite/ui/hooks";
import { IntercomWidget } from "@fllite/ui/components";

interface Props extends AppProps {}

const App = ({ Component, pageProps }: Props) => {
  const [showChild, setShowChild] = useState(false);

  useSentry();

  const isProd = process.env.NODE_ENV === "production";

  useEffect(() => {
    setShowChild(true);
  }, []);

  if ((!showChild || typeof window === "undefined") && !isProd) {
    return <div />;
  }

  return (
    <>
      <Head>
        <title>Fllite !!!</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <link
          rel="sitemap"
          type="application/xml"
          title="Sitemap"
          href="/assets/sitemap.xml"
        ></link>
      </Head>
      <Script src="https://www.google.com/recaptcha/enterprise.js?render=explicit" />
      <AppProviders trackPageChanges>
        <GlobalStyles />
        <CssBaseline />
        <CookieBar />
        <Component {...pageProps} />
      </AppProviders>
    </>
  );
};

interface AppProvidersProps {
  children: ReactNode;
  trackPageChanges?: boolean;
}

export const AppProviders = ({
  children,
  trackPageChanges,
}: AppProvidersProps) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
          },
        },
      }),
  );

  return (
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider theme={muiTheme}>
        <ProvideAnalytics trackPageChanges={trackPageChanges}>
          <FlashMessages>
            <FronfacingAuthWrapper>
              <IntercomWidget>
                <AccountContextWrapper>{children}</AccountContextWrapper>
              </IntercomWidget>
            </FronfacingAuthWrapper>
          </FlashMessages>
        </ProvideAnalytics>
      </MuiThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
